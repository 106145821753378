export default function environment(){
    let origin = 'https://dev.thapardogschool.in';
    let url = window.location.origin;
    let env = 'production'
    if(url.includes('dev.thapardogschool.in')){
        env ='staging';
    }else if(url.includes('admin.thapardogschool.in')){
        env = 'production-admin'
    }else if(url.includes('thapardogschool.in')){
        env = 'production'
    }else{
        env = 'develop'
    }

    if(env==='develop'){
        return {
            env,
            api: 'http://localhost:4000/api/v1',
            origin: 'http://localhost:3000',
            razorpay_client_id: 'rzp_test_bmQtEIn6sNPmSh'
        }
    }else if(env==='staging'){
        return {
            env,
            api: 'https://tds-staging.onrender.com/api/v1',
            origin: 'https://dev.thapardogschool.in',
            razorpay_client_id: 'rzp_test_bmQtEIn6sNPmSh'
        }
    }else if(env==='production-admin'){
        return {
            env,
            api: 'https://tds-api.onrender.com/api/v1',
            origin: 'https://admin.thapardogschool.in',
            razorpay_client_id: 'rzp_live_EZztLsTwlBVZFx'
        }
    }else{
        return {
            env,
            api: 'https://api.thapardogschool.in/api/v1',
            origin: 'https://thapardogschool.in',
            razorpay_client_id: 'rzp_live_EZztLsTwlBVZFx'
        }
    }
}