import React from "react"

import Footer from "../components/Common/Footer"

function Cancellation() {
  return (
    <div>
      <div className="flex flex-1 items-center justify-center text-3xl text-[#313131] tos">
       <div className="container privacy-container">
       <div class="content">
       <h1> Cancellation and Refund Policy</h1>
        <p>There is a Strict no refund & no cancellation policy. You are entitled to a refund only in the case where you have not been allotted the course after payment.</p>
       </div>
       </div>
      </div>
      <div className="hj-o">
      <Footer />
      </div>
    </div>
  )
}

export default Cancellation
