import React from "react"

import Footer from "../components/Common/Footer"

function Shipping() {
  return (
    <div>
     <div className="flex flex-1 items-center justify-center text-3xl text-[#313131] tos">
       <div className="container privacy-container">
       <div class="content">
       <h1> Delivery and Shipping Policy</h1>
        <p>Delivery & shipping of physical products is not applicable.</p>
       </div>
       </div>
      </div>
      <div className="hj-o">
      <Footer />
      </div>
 
    </div>
  )
}

export default Shipping
