import { ACCOUNT_TYPE } from "../utils/constants"

export const sidebarLinks = [
  {
    id: 9,
    name: "Home",
    path: "/",
    icon: "VscHome",
  },
  {
    id: 1,
    name: "My Profile",
    path: "/dashboard/my-profile",
    icon: "VscAccount",
  },
  {
    id: 2,
    name: "Dashboard",
    path: "/dashboard/instructor",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscDashboard",
  },
  {
    id: 6,
    name: "Customers",
    path: "/dashboard/customers",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscBroadcast",
  },
  {
    id: 3,
    name: "My Courses",
    path: "/dashboard/my-courses",
    type: `${ACCOUNT_TYPE.INSTRUCTOR},${ACCOUNT_TYPE.ADMIN}`,
    icon: "VscVm",
  },
  {
    id: 8,
    name: "All Reviews",
    path: "/dashboard/all-reviews",
    type: `${ACCOUNT_TYPE.INSTRUCTOR},${ACCOUNT_TYPE.ADMIN}`,
    icon: "VscComment",
  },
  {
    id: 4,
    name: "Add Course",
    path: "/dashboard/add-course",
    type:  `${ACCOUNT_TYPE.INSTRUCTOR},${ACCOUNT_TYPE.ADMIN}`,
    icon: "VscAdd",
  },
  {
    id: 5,
    name: "Enrolled Courses",
    path: "/dashboard/enrolled-courses",
    type: ACCOUNT_TYPE.STUDENT,
    icon: "VscMortarBoard",
  },
  {
    id: 7,
    name: "Cart",
    path: "/dashboard/cart",
    type: ACCOUNT_TYPE.STUDENT,
    icon: "VscArchive",
  },
]
export const unAuthSidebarLinks = [
  {
    id: 1,
    name: "Home",
    path: "/",
    icon: "VscHome",
  },
  {
    id: 2,
    name: "courses",
    path: "/catalog/courses",
    // type: ACCOUNT_TYPE.ADMIN,
    icon: "VscDashboard",
  },
  {
    id: 6,
    name: "About Us",
    path: "/about",
    icon: "VscReport",
  },
  {
    id: 3,
    name: "Contact Us",
    path: "/contact",
    icon: "VscVm",
  },
  {
    id: 4,
    name: "Login",
    path: "/login",
    icon: "VscVm",
  },
  {
    id: 5,
    name: "Sign up",
    path: "/signup",
    icon: "VscVm",
  },
  
]
