import React from "react"

import Footer from "../components/Common/Footer"

function Terms() {
  return (
    <div>
      <div className="tos flex flex-1 items-center justify-center text-3xl text-[#000] ">
        <div className="privacy-container container">
          <h1>Terms and Conditions</h1>

          <div class="content">
            <p>
              <b>
                <span>Welcome to Thapar Dog School! </span>
              </b>
            </p>
            <p>
              <span>
                thapardogschool.in is owned and operated by Thapar Dog School (“
                <b>Thapar Dog School</b>”, “<b>we</b>”, “<b>us</b>” or “
                <b>our</b>”). These terms and conditions of service (“
                <b>Terms</b>”) govern your access and use of Thapar Dog School
                websites, dog training courses and programs, mobile
                applications, community forums, and other content, features,
                products or services we may offer, as well as your purchase of
                any items or services through our websites or other platforms,
                all of which we refer to collectively as “<b>Services</b>”.
                These Terms also include our Privacy Policy, available at{" "}
                <u>
                  <a href="/privacy-policy">
                    thapardogschool.in/privacy-policy
                  </a>
                </u>
                , which describes how we collect, use, and share your personal
                information when using the Services, and our Refund Policy,
                available at{" "}
                <u>
                  <a href="/refund-policy">thapardogschool.in/refund-policy</a>
                </u>
                . Certain Services may require the acceptance of additional
                terms and conditions specific to that Service, and, if
                applicable, these Terms will include such additional terms and
                conditions.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>
                  By ACCESSING, using, or purchasing any of our Services, you
                  agree to be bound by these Terms. Please read them carefully.
                  These Terms LIMIT OUR LIABILITY TO YOU. If you do not agree to
                  these Terms, you may not ACCESS, use, or purchase any of our
                  Services.
                </span>
              </b>{" "}
            </p>
            <p>
              <span>
                We may modify these Terms and our Privacy Policy and Refund
                Policy from time to time. When material changes are made, we
                will notify you by email or through the Services, and give you
                time to review them. If you disagree with the changes, please
                close your account and stop using the Services. Continuing to
                use the Services after changes take effect constitutes your
                acceptance of the revised Terms.
              </span>{" "}
            </p>
            <p>
              <span>
                If you have any questions about these Terms, please send us an
                email at{" "}
                <u>
                  <a href="mailto:ask@thapardogschool.in">
                    <span class="__cf_email__">[email&#160;protected]</span>
                  </a>
                </u>
                .{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>
                  1. Consent to Receive Calls, Texts, and Other Communications
                </span>
              </b>{" "}
            </p>
            <p>
              <span>
                You expressly consent to receive communications from Thapar Dog
                School, and our partners and affiliates, including telephone
                calls, text messages, emails, and push notifications to any
                phone number, email, or other contact that you provide us. You
                agree that:
              </span>{" "}
            </p>
            <ul>
              <li>
                <span>
                  Phone calls and text messages may be sent using an automatic
                  telephone dialing system or other technology, and contain
                  artificial or prerecorded voice messages.
                </span>{" "}
              </li>
              <li>
                <span>
                  Calls and texts may be made to your mobile number even if it
                  is registered on a Do Not Call list.
                </span>{" "}
              </li>
              <li>
                <span>
                  These communications may serve various business purposes such
                  as advertising, marketing, customer service, transaction
                  processing, account updates and management, and debt
                  collection.
                </span>{" "}
              </li>
              <li>
                <span>
                  {" "}
                  Granting consent to receive these communications is not a
                  condition or requirement for purchasing any products or
                  services from us.
                </span>{" "}
              </li>
            </ul>
            <p>
              <span>
                {" "}
                The frequency of communications from us may vary, and we may
                increase or decrease this frequency at any time. Your carrier's
                standard charges, data rates, and other fees will apply to any
                texts or calls made to mobile numbers. Not all mobile devices
                may be supported and messages may not be deliverable in all
                areas. Thapar Dog School is not liable for any delayed or
                undelivered messages.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>OptingOut</span>
              </b>{" "}
            </p>
            <p>
              <span>
                You may unsubscribe from email communications by following the
                “unsubscribe” link contained in our email and following the
                instructions to complete the unsubscribe process. You may
                opt-out of receiving calls from Thapar Dog School by
                communicating a Do Not Call request to our representative before
                hanging up, or by calling our customer service at +91
                9876143561.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                You acknowledge that opting out of communications may negatively
                impact your use of the Services, and that, after opting-out,
                Thapar Dog School may still send you essential communications
                about your account and the Services, including information about
                products purchased by you, or services you provide to others. In
                addition, we may cease communications to you if we believe that
                your phone number has been reassigned, or you no longer wish to
                receive or are able to receive communications from us.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Call Monitoring and Recording</span>
              </b>{" "}
            </p>
            <p>
              <span>
                You agree that all telephone conversations between you and
                Thapar Dog School or our partners and affiliates, or between you
                and other users when made through the Services, may be monitored
                and recorded for quality assurance and training purposes.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Text Messaging Service</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School may operate a text messaging service (“
                <b>Text Messaging Service</b>”) where we deliver recurring
                SMS/text messages to you about our products and services. You
                may enroll by providing us your mobile phone number, sending
                messages or key words to our short code or phone number, or
                otherwise opting-in to the Text Messaging Service.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                By enrolling, you agree to receive recurring text messages from
                and on behalf of Thapar Dog School through your wireless
                provider to the mobile number you provided, even if your mobile
                number is registered on any state or federal Do Not Call list.
                Text messages may be sent using an automatic telephone dialing
                system or other technology. Service-related messages may include
                updates, alerts, and information (e.g., order updates, account
                alerts, etc.). Promotional messages may include promotions,
                specials, and other marketing offers (e.g., cart reminders).
              </span>{" "}
            </p>
            <p>
              <span>
                You understand that you do not have to enroll in the Text
                Messaging Service in order to make any purchases, and your
                consent to the Text Messaging Service is not a condition of any
                purchase with Thapar Dog School. Your participation in the Text
                Messaging Service is completely voluntary.
              </span>{" "}
            </p>
            <p>
              <span>
                We do not charge for the Text Messaging Service, but you are
                responsible for all charges and fees associated with text
                messaging imposed by your wireless provider. Message frequency
                varies. Message and data rates may apply. Check your mobile plan
                and contact your wireless provider for details. You are solely
                responsible for all charges related to SMS/text messages,
                including charges from your wireless provider.
              </span>{" "}
            </p>
            <p>
              <span>
                You may opt-out of the Text Messaging Service at any time.
                Clicking the unsubscribe link (where available) in any text
                message to cancel. You'll receive a one-time opt-out
                confirmation text message. No further messages will be sent to
                your mobile device, unless initiated by you. If you have
                subscribed to other Thapar Dog School mobile message programs
                and wish to cancel, except where applicable law requires
                otherwise, you will need to opt out separately from those
                programs by following the instructions provided in their
                respective mobile terms.
              </span>{" "}
            </p>
            <p>
              <span>
                For Text Messaging Service support or assistance, call to +91
                9876143761 or email{" "}
                <a href="mailto:ask@thapardogschool.in">
                  <span class="__cf_email__">[email&#160;protected]</span>
                </a>
                .
              </span>{" "}
            </p>
            <p>
              <span>
                We may change any short code or telephone number we use to
                operate the Text Messaging Service at any time and will notify
                you of these changes. You acknowledge that any messages,
                including any STOP or HELP requests, you send to a short code or
                telephone number we have changed may not be received and we will
                not be responsible for honoring requests made in such messages.
              </span>{" "}
            </p>
            <p>
              <span>
                The wireless carriers supported by the Text Messaging Service
                are not liable for delayed or undelivered messages. You agree to
                provide us with a valid mobile number. If you get a new mobile
                number, you will need to sign up for the program with your new
                number. To the extent permitted by applicable law, you agree
                that we will not be liable for failed, delayed, or misdirected
                delivery of any information sent through the Text Messaging
                Service, any errors in such information, or any action you may
                or may not take in reliance on the information or Text Messaging
                Service.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>2. Contact Information and Notices</span>
              </b>{" "}
            </p>
            <p>
              <span>
                You represent that you are the authorized account holder for any
                telephone number or email address that you provide us for
                communicating with you. You also agree to keep your email
                address, phone number, and other contact information current.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                Should we need to inform you about updates to these Terms or
                other important information about your account, we may
                communicate electronically to your current email address, or by
                sending a text to your phone number, or by providing notice
                through the Services. Such electronic communication will
                constitute written notice under these Terms. You agree that all
                agreements, notices, disclosures, and other communications that
                we provide to you electronically will satisfy any legal
                requirement that such communication be in writing.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>3. Eligibility</span>
              </b>
            </p>
            <p>
              <span>
                You must be at least 18 years old to use the Services. We
                reserve the right to require you to prove that you are at least
                18 years old. If you use the Services on behalf of a company or
                other third party, you agree that you are an authorized
                representative of such party and your use of the Services
                constitutes that third party's acceptance of these Terms. In
                addition, you may not use the Services if you have been
                prohibited by us from doing so.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>4. Creating an Account</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Certain Services are accessible only to users with an account.
                You agree to provide accurate, complete, and up-to-date
                information when creating an account and making purchases using
                the Services. You also agree to:
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>Use a strong password </span>
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>Not share your account with others.</span>
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                Keep your account details confidential and information current.
              </span>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School is not responsible for any losses or damages
                resulting from your failure to safeguard your account
                information.
              </span>{" "}
            </p>
            <p id="subscriptions">
              <b>
                <span>
                  5. Subscriptions, Memberships, and Automatic Renewal
                </span>
              </b>{" "}
            </p>
            <p>
              <span>
                We may offer regularly recurring subscriptions for products and
                services, and recurring memberships for premium products and
                services (collectively, “<b>Subscription</b>”), and offers for
                promotional trial periods or discounts applicable to
                Subscriptions. If you purchase a Subscription, your payment
                method will automatically be charged at the start of each
                subscription period for the fees and taxes applicable to that
                period. ALL SUBSCRIPTIONS AUTOMATICALLY RENEW. BY ENROLLING, YOU
                AGREE THAT YOUR SUBSCRIPTION WILL RENEW AUTOMATICALLY AND
                CHARGES WILL CONTINUE UNTIL YOU CANCEL YOUR SUBSCRIPTION, OR
                YOUR SUBSCRIPTION IS TERMINATED OR SUSPENDED BY US, IN
                ACCORDANCE WITH THESE TERMS.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Cancelling Your Subscription</span>
              </b>{" "}
            </p>
            <p>
              <span>
                You may cancel your Subscription at any time by following the
                methods outlined below. To ensure you are not charged for the
                next billing cycle, you must request cancellation at least 14
                days before the end of your current cycle.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                To cancel by phone, please call us at +91 9876143761.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                To cancel online, go to thapardogschool.in. Navigate to “Contact
                Us”
              </span>
              <span></span>
              <span>
                {" "}
                “Send a Message”. Type “Cancel my membership” in the message
                box, and then send us your cancellation request by email.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>6. Purchases and Payments</span>
              </b>{" "}
            </p>
            <p>
              <b>
                <span>Accuracy of Information</span>
              </b>{" "}
            </p>
            <p>
              <span>
                By placing an order, you represent that you will only provide
                payment information which is either yours or which you are
                authorized to provide us to complete your order. We reserve the
                right to cancel any order or to suspend or terminate your
                account if we have grounds to believe that you have provided
                inaccurate, incomplete, or fraudulent information. You agree to
                promptly update your account and other information, including
                your email address and credit card numbers and expiration dates,
                so that we can complete your transactions and contact you as
                needed.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Descriptions and Pricing</span>
              </b>{" "}
            </p>
            <p>
              <span>
                When ordering products or services, please note that we do not
                guarantee the accuracy, completeness, or current status of
                product and service descriptions. Errors may arise in
                descriptions, prices, or other information. We reserve the right
                to discontinue any products and services, change specifications
                and prices, or introduce fees for parts of the Services
                previously offered at no charge, all without prior notice.
                Additionally, we may change pricing for goods and services at
                any time and introduce premium services in the future that may
                entail fees or additional charges.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Product Orders</span>
              </b>{" "}
            </p>
            <p>
              <span>
                All orders for products and services in connection with the
                Services are conditioned upon your acceptance of these Terms. We
                reserve the right to refuse or limit any orders, including
                quantities. Quantities of certain items may be limited and
                inventory cannot always be guaranteed. Thapar Dog School is not
                liable if shipments are delayed or certain items are
                unavailable. Orders for products delivered to you are
                non-cancellable once placed, however, we may grant or deny
                cancellation requests prior to shipment in our discretion. We
                reserve the right to provide substantially similar products and
                services to fulfill your order. Our products and services are
                for your individual, personal use only, and you agree not to
                resell, distribute, or broker any products or services purchased
                from us.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Refunds</span>
              </b>
            </p>
            <p>
              <span>
                Refunds for our products and services are subject to our Refund
                Policy, available at <u>thapardogschool.in/refund-policy</u>.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Payment Authorization</span>
              </b>{" "}
            </p>
            <p>
              <span>
                You authorize us and any payment processor to charge your
                payment card for all purchases you make through the Services,
                including regularly for recurring Subscriptions that
                automatically renew. Your credit or debit card will be charged
                when your order is processed. We reserve the right to store and
                use the payment information you provide us in connection with
                this payment to provide better service to you should you wish to
                use our Services again in the future and to protect us from
                fraud and other losses. Completion of a payment transaction is
                contingent upon your provision of all necessary personal and
                purchase information.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Sales and Use Taxes</span>
              </b>{" "}
            </p>
            <p>
              <span>
                We may be required by law to collect sales or other similar
                taxes or fees, and we reserve the right to charge you for any
                sales tax that we believe we are required to pay or collect
                related to your purchase. Your purchase may also be subject to
                use or similar tax in your jurisdiction, and you are solely
                responsible for the payment of such use tax. If applicable to
                your order, shipping and handling charges will be added and
                appear as a separate charge. Title to and the risk of loss or
                damage of all products purchased and shipped shall pass to you
                at the time we deliver the products to any common carrier for
                shipment.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Promotional Codes</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School may occasionally offer promotional codes for
                discounts on selected purchases at our sole discretion. These
                codes are non-transferable and cannot be exchanged for cash,
                credit, or applied to past purchases, and no cash alternative is
                available. Promotional codes cannot be combined with other
                offers, discounts, or gift cards. Each promotional code must be
                redeemed by the date specified, if any, and cannot be replaced
                if lost. Customers are limited to using one promotional code per
                purchase. We reserve the right to terminate or modify any
                promotional program at our discretion at any time, and
                promotional codes are not valid where they are prohibited by
                law.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Credits</span>
              </b>
            </p>
            <p>
              <b>
                <span>Promotional Credits. </span>
              </b>
              <span>
                Thapar Dog School may occasionally provide credits to users in
                our sole discretion as a form of non-purchased credit that can
                be applied to certain Service transactions (“
                <b>Promotional Credit</b>”). Promotional Credits are not
                redeemable for cash and cannot be sold, traded, copied, or
                transferred. Promotional Credits will expire on a specified
                date, if provided. Thapar Dog School may revoke or change the
                terms applicable to Promotional Credits at any time, without
                prior notice to you.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Credits for Prepaid Services.</span>
              </b>
              <span>
                &nbsp;Thapar Dog School may issue credits that correspond to
                amounts paid for specific services, such as virtual or in-person
                dog training sessions. These credits must be used within 24
                months from the date of issuance unless applicable law at the
                time of your purchase requires a longer period. If a longer
                period is mandated, these credits will expire in accordance with
                such requirements.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Other Credits and Promotional Programs.</span>
              </b>
              <span>
                {" "}
                Thapar Dog School may, at its sole discretion, introduce various
                credits and promotional programs from time to time. These may be
                connected to specific promotions, programs, or initiatives and
                are not treated the same as Promotional Credits or credits for
                prepaid services. Each type of credit or promotional program has
                unique terms and conditions that govern their use, and
                participants are subject to these specific terms. Thapar Dog
                School retains the right to modify, terminate, or discontinue
                any such credit or promotional program at its discretion,
                without prior notice or additional obligation to participants,
                except as mandated by applicable law.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>7. Dog Training</span>
              </b>
              <span>
                {" "}
                <b>Services</b>
              </span>{" "}
            </p>
            <p>
              <span>
                The Services include a platform that enables dog owners and dog
                trainers to find and connect with each other to conduct dog
                training sessions, either virtually online or in-person in their
                local area. Trainers made available through the Services are
                independent service providers who determine in their own
                discretion how to provide dog training services based on their
                own experience and judgment. Thapar Dog School has no control
                over the conduct of trainers or owners, or any dogs involved in
                training sessions. Thapar Dog School makes no representations or
                warranties about the quality or effectiveness of the dog
                training services provided by dog trainers, or about your
                interactions and dealings with trainers, owners, or any other
                users of the Services.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                Training sessions are for general dog training, behavior, and
                socialization, and not intended to provide veterinary, health or
                nutritional advice for your dog. Always seek the advice of a
                qualified veterinarian for any questions involving the health of
                your pet. Owners are solely responsible for any loss or damage
                resulting from reliance upon any information provided by
                trainers or made available through the Services.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                For in-person training sessions, owners and trainers should
                arrange to meet in a safe, secure environment. We recommend
                beginning with virtual training sessions so owners and trainers
                can assess trainer compatibility and observe initial improvement
                in the dog's behavior. When planning in-person training
                sessions, trainers and owners should discuss and identify an
                appropriate location based on factors such as availability,
                safety, training goals, and the likelihood of distraction or
                negative encounters with other people or dogs. Owners are solely
                responsible for the actions of their dogs, and any damages,
                losses, or harm caused by their dogs during a training session.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                YOU USE THE SERVICES AT YOUR OWN RISK. ALWAYS PRIORITIZE SAFETY
                WHEN TRAINING DOGS, AND AVOID ACTIONS THAT COULD ENDANGER YOU OR
                OTHERS.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School DISCLAIMS ALL LIABILITY FOR (I) YOUR USE OF OR
                RELIANCE UPON INFORMATION PROVIDED THROUGH THE SERVICES OR BY
                TRAINERS OR OWNERS, AND (II) THE OUTCOMES OF TRAINING SESSIONS.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Service Availability</span>
              </b>{" "}
            </p>
            <p>
              <span>
                We aim to provide the best dog training experience for our
                customers. However, there may be occasions when a dog trainer is
                not immediately available in your area. We cannot guarantee the
                immediate availability of a trainer and will not be held liable
                for any delays or unavailability. Additionally, we reserve the
                right to replace your trainer at any time without liability.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>8. Veterinary Consultation Services</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School may provide users with access to online
                veterinary consultation services, which are fulfilled by an
                independent third-party provider. These services are intended to
                serve as an educational resource to support the decisions you
                make about your dogs' care and are distinct from other Thapar
                Dog School Services. While these consultations may complement
                professional veterinary care, they are not intended to be a
                replacement. Always consult with a veterinarian for definitive
                advice and care for your pets. If any claims or disputes arise
                from using these consultation services, you agree to address
                them directly with the respective third-party provider and not
                with Thapar Dog School.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>9. Other Services and Information Sharing</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Our Services may allow messaging and sharing of information in
                various ways, such as through user forums, and messages to and
                interactions with trainers or other users. When you disseminate
                information through our Services, it becomes accessible and
                usable by others, both within and outside of our Services.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                By using the Services, you may encounter content or information
                that might be inaccurate, incomplete, delayed, misleading,
                illegal, offensive, or otherwise harmful. Thapar Dog School
                generally does not review content provided by our users. You
                agree that we are not responsible for other users' content or
                information, or the misuse of our Services by others.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>10. Third-Party Content</span>
              </b>{" "}
            </p>
            <p>
              <span>
                The Services may contain information, advertisements, or other
                content provided by other users, third parties, or links to
                third-party websites. Thapar Dog School does not endorse,
                monitor, or assume responsibility or liability for any
                third-party sites, content, or services, including but not
                limited to any of their policies or practices. You may be
                required to agree to the third-party's terms of service and
                privacy policy in order to use their content or service. Any
                charges or obligations you incur in your dealings with such
                third parties are your responsibility.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>11. License to Use the Services</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Subject to your agreement to, and continuing compliance with,
                these Terms and all other relevant Thapar Dog School policies,
                Thapar Dog School grants you a limited, non-exclusive,
                non-sublicensable, non-transferable, and revocable right and
                license to access and use the Services. Any use of the Services
                in violation of these Terms is prohibited, and may result in the
                immediate revocation of your limited license to the Services, as
                well as subject you to liability for violations of law. Thapar
                Dog School may limit or restrict use of the Services by anyone
                who Thapar Dog School reasonably believes has or will violate
                these Terms or any applicable laws when using the Services. In
                addition, Thapar Dog School reserves the right to monitor your
                use of the Services to ensure compliance with these Terms.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>12. Your Contributions to Thapar Dog School</span>
              </b>{" "}
            </p>
            <p>
              <b>
                <span>“User Content” </span>
              </b>
              <span>
                refers to any text, images, artwork, ideas, questions, reviews,
                comments, messages, communications with us or others,
                suggestions, reviews, feedback, or other content that you
                submit, post, or disseminate using the Services or provide to
                us, as well as your name, photograph, and any biographical
                information submitted with any User Content.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>License to Thapar Dog School.</span>
              </b>
              <span>
                &nbsp;By sharing, submitting, or uploading User Content, you
                grant Thapar Dog School, and its affiliated companies and
                sublicensees, a non-exclusive, irrevocable, worldwide,
                transferable license to use your User Content in connection with
                the operation of the Services, including, without limitation,
                the rights to:{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                copy, distribute, transmit, publicly display, publicly perform,
                reproduce, edit, translate, and reformat User Content; and
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                sublicense such rights to any supplier of the Services.
              </span>{" "}
            </p>
            <p>
              <span>
                You retain ownership of User Content, subject to the license
                granted to us in this section.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Waiver of Moral Rights.</span>
              </b>
              <span>
                {" "}
                You irrevocably waive and agree not to assert any rights,
                including any “moral rights”, that you may have to prevent
                Thapar Dog School from exploiting any of the rights to use User
                Content granted by you in these Terms.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Your Responsibility for User Content.</span>
              </b>
              <span>
                &nbsp;You are solely responsible for all User Content made
                available through the Services. By providing User Content, you
                represent and warrant that:
              </span>{" "}
            </p>
            <ul>
              <li>
                <span>You are 18 years or older.</span>{" "}
              </li>
              <li>
                <span>
                  You possess the necessary rights, licenses, consents, and
                  releases to grant Thapar Dog School the rights to use and
                  disseminate the User Content.
                </span>{" "}
              </li>
              <li>
                <span>
                  Your provision of User Content, and its use by Thapar Dog
                  School, will not infringe, misappropriate, or violate any
                  third party's patents, copyrights, trademarks, trade secrets,
                  moral rights, or other intellectual property rights, rights of
                  publicity, privacy, or any other right, or result in the
                  violation of any applicable law or regulation.
                </span>{" "}
              </li>
            </ul>
            <p>
              <b>
                <span>No Obligations Regarding User Content.</span>
              </b>
              <span>
                &nbsp;Thapar Dog School has no obligation to treat User Content
                as confidential, or compensate you or any third party for its
                use, or store or maintain any User Content. We reserve the
                right, at our sole discretion, to remove, delete, edit, or
                access User Content for any reason, without providing notice or
                obtaining approval from you or any third party.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>13. Our Intellectual Property Rights</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Other than User Content, Thapar Dog School either owns or holds
                licenses for all right, title, and interest in and to (a) the
                Services, which includes all software, information, text, media,
                videos, and other content available through the Services
                (collectively, “<b>Our Content</b>”), and (b) our trademarks,
                service marks, logos, and brand elements (collectively, “
                <b>Marks</b>”). Our Content specifically encompasses our online
                dog training courses delivered through various media formats,
                including video. The Services, Our Content, and Marks are all
                comprehensively protected under both U.S. and international
                laws. Unauthorized duplication, copying, or reuse of any portion
                of the Services, Our Content, or Marks without express written
                permission from Thapar Dog School is strictly prohibited.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>14. Copyright Infringement Claims</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School takes copyright infringement claims seriously.
                We will respond to notices of alleged copyright infringement
                that comply with applicable law. If you have evidence or good
                faith belief that content accessible from the Services infringes
                upon your copyright, you may request their removal by submitting
                a written notification to Thapar Dog School Copyright Agent by
                email to{" "}
                <u>
                  <a href="mailto:ask@thapardogschool.in">
                    <span class="__cf_email__">[email&#160;protected]</span>
                  </a>
                </u>
                .
              </span>{" "}
            </p>
            {/* <p><span>To conform with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), please include the information set forth below in your notice. Failure to comply with all requirements of Section 512(c)(3) of the DMCA may render your DMCA Notice ineffective.</span> </p> */}
            <ul>
              <li>
                <span>
                  Identification of the copyrighted work you claim has been
                  infringed, or if multiple copyrighted works are covered by a
                  single notification, a representative list of such works.
                </span>{" "}
              </li>
              <li>
                <span>
                  Sufficient identification of the material alleged to be
                  infringing, enabling us to locate it. Please provide a URL and
                  screenshots for each item.
                </span>{" "}
              </li>
              <li>
                <span>
                  Details of your claim to the material, or relationship to the
                  copyright holder of the materials.
                </span>{" "}
              </li>
              <li>
                <span>
                  Your contact information for us to reach you, including your
                  name, mailing address, telephone number, and email address.
                </span>{" "}
              </li>
              <li>
                <span>
                  A statement of your good faith belief that the disputed use of
                  the copyrighted material is not authorized by the copyright
                  owner, its agent, or the law.
                </span>{" "}
              </li>
              <li>
                <span>
                  A declaration under penalty of perjury that the information
                  provided in the notice is accurate, and you are, or you are
                  authorized to act on behalf of, the copyright owner to make
                  this complaint.
                </span>{" "}
              </li>
              <li>
                <span>
                  Your physical or electronic signature of the infringement
                  notice.
                </span>{" "}
              </li>
            </ul>
            <p>
              <b>
                <span>15. User Conduct</span>
              </b>{" "}
            </p>
            <p>
              <span>
                Thapar Dog School is a community of dog lovers and our goal is
                to foster a positive and safe training environment for our
                users. To this end, you agree to use the Services for their
                designated purposes, and to comply with all applicable local,
                state, and federal laws, rules, and regulations while using the
                Services.
              </span>{" "}
            </p>
            <p>
              <span>
                You also agree not to do any of the following, either directly
                or indirectly, in connection with the Services:
              </span>{" "}
            </p>
            <ul>
              <li>
                <span>
                  Participate in or promote any fraudulent or illegal
                  activities.
                </span>{" "}
              </li>
              <li>
                <span>
                  Misrepresent your identity, credentials, or affiliations;
                  impersonate any individual; use accounts under false
                  identities; or access another user's account without
                  permission.
                </span>{" "}
              </li>
              <li>
                <span>
                  Attempting unauthorized access to the Services, other users'
                  accounts, or any computers, servers, or networks related to
                  the Services.
                </span>{" "}
              </li>
              <li>
                <span>
                  Copy, download, distribute, or publish any content from the
                  Services (including any Thapar Dog School instructional videos
                  or training programs), unless explicitly authorized by these
                  Terms or in a separate written agreement from us.
                </span>{" "}
              </li>
              <li>
                <span>
                  Post content or comments that may be perceived as obscene,
                  pornographic, offensive, racist, sexist, or that promote hate
                  speech and discrimination.
                </span>{" "}
              </li>
              <li>
                <span>
                  Engage in behavior that harasses, bullies, defames,
                  discriminates, threatens, or stalks other users or
                  individuals, or infringes upon the rights or privacy of
                  others.
                </span>{" "}
              </li>
              <li>
                <span>
                  Use the Services to transmit spam, unwarranted advertisements,
                  or phishing scams.
                </span>{" "}
              </li>
              <li>
                <span>
                  Upload or transmit any viruses, malware, or other malicious
                  content that could harm or disrupt the Services or the devices
                  of other users.
                </span>{" "}
              </li>
              <li>
                <span>
                  Use automated software, bots, or other technology to gather or
                  manipulate data from the Services, including user profiles and
                  user information, or to scrape, crawl, copy, or duplicate any
                  part of the Services.
                </span>{" "}
              </li>
              <li>
                <span>
                  Publish or share personal information about other users or
                  individuals without their explicit consent. This includes
                  addresses, phone numbers, or other personal information.
                </span>{" "}
              </li>
              <li>
                <span>
                  Infringe upon the copyrights, patents, trademarks, service
                  marks, trade secrets, or other rights of other individuals or
                  organizations, or of Thapar Dog School. Unauthorized use of
                  our logos, trademarks, service marks, or online dog training
                  courses is strictly prohibited.
                </span>{" "}
              </li>
              <li>
                <span>
                  Manipulate our ratings or review systems, such as posting
                  false reviews or offering compensation for positive reviews.
                </span>{" "}
              </li>
              <li>
                <span>
                  Use the Services to develop competitive products or services,
                  or to engage in unauthorized commercial activities or
                  promotions. This includes any kind of solicitation,
                  advertising, or promotion of products by owners or trainers
                  unrelated to our Services.
                </span>{" "}
              </li>
              <li>
                <span>
                  Disrupt or interfere with the normal operation of the Services
                  or negatively affect the user experience, such as exploiting
                  website or software vulnerabilities, conducting denial of
                  service attacks, or bypassing security measures.
                </span>{" "}
              </li>
              <li>
                <span>
                  Misuse the reporting or complaint features in the Services,
                  such as making ungrounded false reports about other users.
                </span>{" "}
              </li>
              <li>
                <span>
                  Use the Services in a manner that may pose a risk to your
                  safety or the safety of others, including during in person
                  training sessions.
                </span>{" "}
              </li>
              <li>
                <span>
                  Modify, make derivative works of, disassemble, decompile, or
                  reverse engineer any part of the Services.
                </span>{" "}
              </li>
            </ul>
            <p>
              <b>
                <span>16. Indemnification</span>
              </b>
            </p>
            <p>
              <span>
                You agree to indemnify Thapar Dog School, and its members and
                affiliates, and their respective officers, managers, employees,
                contractors, and representatives (collectively, “
                <b>Affiliates</b>”), against all losses, liabilities, costs, and
                expenses (including reasonable attorney fees and court costs),
                arising from:
              </span>{" "}
            </p>
            <ul>
              <li>
                <span>
                  Any breach of these Terms by you or someone using your
                  account;
                </span>{" "}
              </li>
              <li>
                <span>
                  Use of the Services, whether properly or improperly, by you or
                  someone using your account;
                </span>{" "}
              </li>
              <li>
                <span>
                  Interactions or disputes you have with other users of the
                  Services, including other dog trainers or owners, online or
                  offline;
                </span>{" "}
              </li>
              <li>
                <span>
                  Information or content you or someone using your account
                  submit or transmit through the Services, including User
                  Content;
                </span>{" "}
              </li>
              <li>
                <span>Violations of any laws by you; and</span>{" "}
              </li>
              <li>
                <span>
                  Actions of your dog or any animal under your care, including
                  damages or injuries they cause.
                </span>{" "}
              </li>
            </ul>
            <p>
              <span>
                You agree to cooperate with Thapar Dog School in defending these
                claims. Thapar Dog School retains the exclusive right to manage
                the legal defense of any matter subject to indemnification,
                including the terms of settlement. You may not settle any such
                matter without our prior written approval.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>17. DISCLAIMER OF WARRANTIES</span>
              </b>{" "}
            </p>
            <p>
              <span>
                You are responsible for assessing the accuracy, completeness,
                and usefulness of information, opinions, advice, or other
                content available through our Services. We shall not be liable
                for any loss or damage resulting from:{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>Your use of the Services;</span>
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                Any malfunction or failure of the Services to operate; or
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                Your reliance on information obtained through our Services, or
                information provided by another user.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>SERVICES PROVIDED “AS IS”: </span>
              </b>
              <span>
                OUR SERVICES AND ALL CONTENT AVAILABLE ON OR THROUGH THE
                SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
                WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND. Thapar Dog
                School AND ITS AFFILIATES DISCLAIM ALL WARRANTIES, WHETHER
                EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT LIMITATION,
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, AND NON-INFRINGEMENT. IN PARTICULAR AND WITHOUT
                LIMITATION, WE DO NOT WARRANT THAT:
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                INFORMATION PROVIDED THROUGH THE SERVICES WILL BE ACCURATE OR
                ERROR-FREE;
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                OUR SERVICES WILL MEET YOUR REQUIREMENTS OR ACHIEVE ANY
                PARTICULAR RESULTS;
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                THE SERVICES WILL BE SECURE, UNINTERRUPTED, OR FREE OF ERRORS,
                OR FREE OF VIRUSES OR OTHER HARMFUL CONTENT; OR
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>DEFECTS IN THE SERVICES WILL BE CORRECTED.</span>{" "}
            </p>
            <p>
              <b>
                <span>18. LIMITATION OF LIABILITY</span>
              </b>{" "}
            </p>
            <p>
              <b>
                <span>NO CONSEQUENTIAL DAMAGES:</span>
              </b>
              <span>
                {" "}
                Thapar Dog School AND ITS AFFILIATES WILL NOT BE LIABLE FOR ANY
                INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL
                DAMAGES (INCLUDING, WITHOUT LIMITATION, CLAIMS FOR LOST PROFITS,
                LOST BUSINESS OPPORTUNITIES, LOSS OF REPUTATION OR GOODWILL,
                LOSS OF DATA, AND COST OF REPLACING THE SERVICES) ARISING FROM
                OR RELATED TO YOUR USE OF THE SERVICES OR THESE TERMS. THIS
                LIMITATION APPLIES REGARDLESS OF WHETHER THE LIABILITY IS BASED
                ON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT
                LIABILITY, VIOLATION OF STATUTE, OR OTHER LEGAL THEORY, AND
                WHETHER OR NOT WE'VE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES.&nbsp;
              </span>{" "}
            </p>
            <p>
              <b>
                <span>
                  NOT RESPONSIBLE FOR YOUR OR OTHERS' CONDUCT OR MATERIALS:
                </span>
              </b>
              <span>
                {" "}
                Thapar Dog School AND ITS AFFILIATES WILL NOT BE LIABLE FOR ANY
                CLAIMS, LOSSES, LIABILITIES, OR DAMAGES ARISING FROM:
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                YOUR OWN CONDUCT, INCLUDING ANY VIOLATION OF THESE TERMS;
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                THE CONDUCT OF THIRD PARTIES, INCLUDING DOG TRAINERS AND OTHER
                USERS OF THE SERVICES; OR
              </span>{" "}
            </p>
            <p>
              <span>
                ·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>
              <span>
                ANY THIRD-PARTY INFORMATION, CONTENT, MATERIALS, OR LINKS
                ACCESSIBLE THROUGH THE SERVICES.{" "}
              </span>{" "}
            </p>
            <p>
              <span>
                SPECIFICALLY, AND WITHOUT LIMITATION, WE ASSUME NO
                RESPONSIBILITY OR LIABILITY FOR BODILY INJURY, INJURY TO ANY
                PETS, EMOTIONAL DISTRESS, OR OTHER DAMAGES, LOSSES OR HARM THAT
                MAY OCCUR DUE TO YOUR USE OF THE SERVICES OR INTERACTIONS WITH
                DOG TRAINERS OR OTHER USERS, WHETHER ONLINE OR OFFLINE.&nbsp;
              </span>{" "}
            </p>
            <p>
              <b>
                <span>MONETARY LIMITATION:</span>
              </b>
              <span>
                {" "}
                THE TOTAL AGGREGATE LIABILITY OF Thapar Dog School AND ITS
                AFFILIATES FOR ALL CLAIMS, CUMULATIVELY, ARISING FROM THESE
                TERMS OR RELATED TO THE PROVISION OF SERVICES TO YOU SHALL NOT
                EXCEED THE TOTAL AMOUNT YOU HAVE PAID TO Thapar Dog School IN
                THE ONE HUNDRED AND EIGHTY (180) CALENDAR DAYS IMMEDIATELY
                PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Applicability:</span>
              </b>
              <span>
                {" "}
                Except for cases of gross negligence or willful misconduct, the
                limitations of liability and disclaimers contained in these
                Terms shall apply to the fullest extent permitted by applicable
                law.<b> </b>These limitations constitute a fundamental element
                of the agreement between you and Thapar Dog School, and shall
                apply to all claims, even if they fail their essential purpose.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>19. Governing Law and Venue</span>
              </b>{" "}
            </p>
            <p>
              <span>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of the State of Punjab, India. Any
                dispute arising out of or in connection with these terms,
                including any question regarding its existence, validity, or
                termination, shall be subject to the exclusive jurisdiction of
                the courts located in Patiala, Punjab, India. By accessing or
                using our website, you consent to the exclusive jurisdiction and
                venue of the courts in Patiala, Punjab, India, for any and all
                disputes, claims, and actions arising out of or in connection
                with these terms.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>20. Termination and Changes to the Services</span>
              </b>{" "}
            </p>
            <p>
              <b>
                <span>Termination:</span>
              </b>
              <span>
                {" "}
                We reserve the right, at our sole discretion, to restrict,
                suspend, or terminate your access to our Services for any reason
                and at any time. Termination notice may be provided
                electronically to the email address associated with your
                account. We are not liable to you or any third party for
                termination of your access to our Services.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Modifications to Services:</span>
              </b>
              <span>
                {" "}
                At our sole discretion, we may update, modify, limit, or
                discontinue our Services periodically. Such changes may involve
                the addition or removal of content or features, conversion of
                free services to paid ones (and vice versa), or alterations to
                user connections and interaction capabilities within the
                Services, such as restrictions on your ability to contact other
                users or trainers, and their ability to contact you.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Your Termination Rights:</span>
              </b>
              <span>
                {" "}
                You have the right to terminate these Terms at any time by
                discontinuing use of our Services.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Effect of Termination:</span>
              </b>
              <span>
                {" "}
                Any obligations accrued prior to termination, and certain
                provisions of these Terms which, by their nature, should survive
                termination, shall survive and remain in effect following any
                termination of these Terms, including but not limited to
                Sections 12 through 14, and 16 through 19.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>21. Other Provisions</span>
              </b>{" "}
            </p>
            <p>
              <b>
                <span>Severability:</span>
              </b>
              <span>
                {" "}
                Should any provision of these Terms be deemed invalid or
                unenforceable by a court of competent jurisdiction, the parties
                hereby agree that such court should, to the extent permissible
                by law, endeavor to modify the provision in a manner that most
                closely reflects the original intentions of the parties as
                manifested in the provision, while maintaining its validity and
                enforceability. In the event that such modification is not
                feasible, the invalid or unenforceable provision shall be deemed
                severed from these Terms, and all remaining provisions shall
                continue in full force and effect.
              </span>{" "}
            </p>
            <p>
              <b>
                <span>No Waiver:</span>
              </b>
              <span>
                {" "}
                Any failure or delay by us to enforce any right or provision
                within these Terms shall not be construed as a waiver of such
                right or provision in any future instance.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Assignment: </span>
              </b>
              <span>
                These Terms, and any rights or licenses granted to you
                hereunder, may not be assigned or delegated by you. Thapar Dog
                School may freely assign or delegate these Terms, and any rights
                or licenses granted hereunder, without restriction.{" "}
              </span>{" "}
            </p>
            <p>
              <b>
                <span>Force Majeure:</span>
              </b>
              <span>
                {" "}
                Under no circumstances will Thapar Dog School be held liable for
                any delay or failure to perform under these Terms due in whole
                or in part to any acts of nature or other causes beyond our
                reasonable control.
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Terms
