import { useEffect, useState } from "react"
import { AiOutlineMenu, AiOutlineShoppingCart,AiOutlineClose } from "react-icons/ai"
import { BsChevronDown } from "react-icons/bs"
import { useSelector } from "react-redux"
import { Link, matchPath, useLocation } from "react-router-dom"

// import logo from "../../assets/Logo/Logo-Full-Light.png"
import { NavbarLinks } from "../../data/navbar-links"
import { apiConnector } from "../../services/apiConnector"
import { categories } from "../../services/apis"
import { ACCOUNT_TYPE } from "../../utils/constants"
import ProfileDropdown from "../core/Auth/ProfileDropdown"

// const subLinks = [
//   {
//     title: "Python",
//     link: "/catalog/python",
//   },
//   {
//     title: "javascript",
//     link: "/catalog/javascript",
//   },
//   {
//     title: "web-development",
//     link: "/catalog/web-development",
//   },
//   {
//     title: "Android Development",
//     link: "/catalog/Android Development",
//   },
// ];

function Navbar({setShowMobileNav, showMobileNav, setOverLay}) {
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const { totalItems } = useSelector((state) => state.cart)
  const location = useLocation()

  const [subLinks, setSubLinks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const res = await apiConnector("GET", categories.CATEGORIES_API)
        setSubLinks(res.data.data)
      } catch (error) {
        console.log("Could not fetch Categories.", error)
      }
      setLoading(false)
    })()
  }, [])

  // console.log("sub links", subLinks)

  const matchRoute = (route) => {
    return matchPath({ path: route }, location.pathname)
  }

  return (
    <div
      className={`flex h-20 items-center justify-center border-b-richblack-700 shadow-all ${
        location.pathname !== "/" ? "bg-stone-50" : ""
      } transition-all duration-200`}
      id='navBar'
    >
      <div className="flex w-11/12 max-w-maxContent items-center justify-between">
        {/* Logo */}
        <Link to="/">
         
          <img src="https://res.cloudinary.com/dozkyuutd/image/upload/v1710588476/testFolder2/pjdjs9ygcyylxhfjs7ku.png" alt="Logo" width={204} loading="lazy" />
          
        </Link>
        {/* Navigation links */}
        <nav className="hidden md:block">
          <ul className="flex gap-10 ">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                {link.title === "Catalog" ? (
                  <>
                    <div
                      className={`group relative flex cursor-pointer items-center gap-1 text-sm font-semibold ${
                        matchRoute("/catalog/:catalogName")
                        ? "text-[#dbb4ac]"
                        : "text-[#313131]"
                      }`}
                    >    
              
                      <p className="hover:text-[#dbb4ac]">{link.title}</p>
                      <BsChevronDown />
                      <div className="invisible absolute left-[50%] top-[50%] z-[1000] flex w-[200px] translate-x-[-50%] shadow-md translate-y-[3em]  border-t-2 border-[#dbb4ac] flex-col bg-white p-4 text-richblack-900 opacity-0 transition-all duration-150 group-hover:visible group-hover:translate-y-[2.85em] group-hover:opacity-100 lg:w-[200px] shadow[0px_7px_7px_0px_rgba(0, 0, 0, 0.04)]">
                        {/* <div className="absolute left-[50%] top-0 -z-10 h-6 w-6 translate-x-[80%] translate-y-[-40%] rotate-45 select-none rounded bg-richblack-5"></div> */}
                        {loading ? (
                          <p className="text-center">Loading...</p>
                        ) : subLinks.length ? (
                          <>
                            {subLinks
                              ?.filter(
                                (subLink) => subLink?.courses?.length > 0
                              )
                              ?.map((subLink, i) => (
                                <Link
                                  to={`/catalog/${subLink.name
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`}
                                  className=" py-4 pl-4 text-xs text-[#818181] border-b border-[#EBEAEC] hover:text-[#313131] last:border-b-0"
                                  key={i}
                                >
                                  <p>{subLink.name}</p>
                                </Link>
                              ))}
                          </>
                        ) : (
                          <p className="text-center">No Courses Found</p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Link to={link?.path}>
                    <p
                      className={`text-sm font-semibold hover:text-[#dbb4ac] ${ 
                        matchRoute(link?.path)
                        ? "text-[#dbb4ac]"
                        : "text-[#313131]"
                      }`}
                    >
                      {link.title}
                  
                    </p>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
        {/* Login / Signup / Dashboard */}
        <div className="hidden items-center gap-x-4 md:flex">
          {user && user?.accountType === ACCOUNT_TYPE.STUDENT && (
            <Link to="/dashboard/cart" className="relative">
              <AiOutlineShoppingCart className="text-2xl text-richblack-100" />
              {totalItems > 0 && (
                <span className="absolute -bottom-2 -right-2 grid h-5 w-5 place-items-center overflow-hidden rounded-full bg-richblack-600 text-center text-xs font-bold text-yellow-100">
                  {totalItems}
                </span>
              )}
            </Link>
          )}
          {token === null && (
            <Link to="/login">
              <button className="rounded-[25px] border-2 border-[#dbb4ac]  px-[12px] py-[8px] text-[#313131] text-sm font-semibold hover:bg-[#dbb4ac7d]">
                Log in
              </button>
            </Link>
          )}
          {token === null && (
            <Link to="/signup">
              <button className="rounded-[25px] border-2 border-[#dbb4ac]  px-[12px] py-[8px] text-[#313131] text-sm font-semibold hover:bg-[#dbb4ac7d]">
                Sign up
              </button>
            </Link>
          )}
          {token !== null && <ProfileDropdown />}
        </div>
        <div className="mr-4 md:hidden flex gap-5">
          {user && user?.accountType === ACCOUNT_TYPE.STUDENT && (
            <Link to="/dashboard/cart" className="relative">
              <AiOutlineShoppingCart className="text-2xl text-richblack-100" />
              {totalItems > 0 && (
                <span className="absolute -bottom-2 -right-2 grid h-5 w-5 place-items-center overflow-hidden rounded-full bg-richblack-600 text-center text-xs font-bold text-yellow-100">
                  {totalItems}
                </span>
              )}
            </Link>
            )}
        { (!showMobileNav ? <button className="mr-4 md:hidden" onClick={()=>{setShowMobileNav(true); setOverLay(true)}}>
          <AiOutlineMenu fontSize={24} fill="#AFB2BF" />
        </button>: <button className="mr-4 md:hidden" onClick={()=>{setShowMobileNav(false); setOverLay(false)}}>
          <AiOutlineClose fontSize={24} fill="#AFB2BF" />
        </button> )}
        </div>
{/*     
          <ProfileDropdown /> */}
      </div>
    </div>
  )
}

export default Navbar
