import { useDispatch, useSelector } from "react-redux"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useState } from "react"
import { FaCheck } from "react-icons/fa"
import { FiEdit2 } from "react-icons/fi"
import { HiClock } from "react-icons/hi"
import { RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom"

import { formatDate } from "../../../../services/formatDate"
import {
 getAllCustomers
} from "../../../../services/operations/customerDetailsAPI"
import ConfirmationModal from "../../../Common/ConfirmationModal"

export default function CustomerTable({ customers, setCustomers }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(null)
  const TRUNCATE_LENGTH = 30

  const handleCustomerDelete = async (customerId) => {
    setLoading(true)
    const result = await getAllCustomers(token)
    if (result) {
        setCustomers(result)
    }
    setConfirmationModal(null)
    setLoading(false)
  }

  // console.log("All Course ", courses)

  return (
    <>
      <Table className="rounded-xl border border-richblack-800 ">
        <Thead>
          <Tr className="flex gap-x-10 rounded-t-md border-b border-b-richblack-800 px-6 py-2">
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Image
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Name
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Mobile
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Email
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {customers?.length === 0 ? (
            <Tr>
              <Td className="py-10 text-center text-2xl font-medium text-richblack-300">
                No customer found
                {/* TODO: Need to change this state */}
              </Td>
            </Tr>
          ) : (
            customers?.map((customer) => (
              <Tr
                key={customer._id}
                className="flex gap-x-10 border-b border-richblack-800 px-6 py-5"
              >
                <Td className="flex-1 gap-x-4">
                  <img
                    src={customer?.image || 'https://res.cloudinary.com/dozkyuutd/image/upload/v1706805164/testFolder/hw0ixt8jjxcvdwemmn5r.jpg'}
                    alt={customer?.firstName}
                    className="h-[75px] w-[75px] rounded-lg object-cover"
                  />
                  </Td>
                  <Td className="flex-1 text-sm font-medium text-richblack-300">
                  <div className="flex flex-col justify-between">
                    <p className="text-lg font-semibold text-richblack-300">
                      {customer.firstName + ' ' + customer.lastName}
                    </p>
                    <p className="text-[12px] text-richblack-200">
                      Created: {formatDate(customer.createdAt)}
                    </p>
                    {customer.courses.length ==0 ? (
                      <p className="flex w-fit flex-row items-center gap-2 rounded-full bg-richblack-700 px-2 py-[2px] text-[12px] font-medium text-pink-100">
                        <HiClock size={14} />
                        Lead
                      </p>
                    ) : (
                      <p className="flex w-fit flex-row items-center gap-2 rounded-full bg-richblack-700 px-2 py-[2px] text-[12px] font-medium text-yellow-100">
                        <div className="flex h-3 w-3 items-center justify-center rounded-full bg-yellow-100 text-richblack-700">
                          <FaCheck size={8} />
                        </div>
                        Client
                      </p>
                    )}
                  </div>
                </Td >
                <Td className="flex-1 text-sm font-medium text-richblack-300">
                  {customer.mobile}
                </Td>
                <Td className="flex-1 text-sm font-medium text-richblack-300">
                  {customer.email}
                  <p>
                    {customer._id}
                  </p> 
                
                </Td>
                <Td className="flex-1 text-sm font-medium text-richblack-300 ">
                  <button
                    disabled={loading}
                    onClick={() => {
                    //   navigate(`/dashboard/edit-course/${course._id}`)
                    }}
                    title="Edit"
                    className="px-2 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
                  >
                    <FiEdit2 size={20} />
                  </button>
                  <button
                    disabled={loading}
                    onClick={() => {
                      setConfirmationModal({
                        text1: "Do you want to delete this course?",
                        text2:
                          "All the data related to this course will be deleted",
                        btn1Text: !loading ? "Delete" : "Loading...  ",
                        btn2Text: "Cancel",
                        btn1Handler: !loading
                          ? () => handleCustomerDelete(customer._id)
                          : () => {},
                        btn2Handler: !loading
                          ? () => setConfirmationModal(null)
                          : () => {},
                      })
                    }}
                    title="Delete"
                    className="px-1 transition-all duration-200 hover:scale-110 hover:text-[#ff0000]"
                  >
                    <RiDeleteBin6Line size={20} />
                  </button>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </>
  )
}
