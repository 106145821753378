export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  // {
  //   title: "Catalog",
  //   // path: '/catalog',
  // },
    {
    title: "Courses",
    path: '/catalog/courses',
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
];
