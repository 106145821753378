import { useDispatch, useSelector } from "react-redux"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useState } from "react"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

import { useNavigate } from "react-router-dom"

import { formatDate } from "../../../../services/formatDate"
import {
 getAllReviews, toggleReviewVisibility
} from "../../../../services/operations/customerDetailsAPI"
import ConfirmationModal from "../../../Common/ConfirmationModal"

export default function ReviewsTable({ reviews, setReviews, showType }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(null)
  const TRUNCATE_LENGTH = 30

  const handleToggleReview= async (reviewId, status) => {
    setLoading(true)
    await toggleReviewVisibility(reviewId, {active:status}, token)
    const result = await getAllReviews(token)
    if (result) {
      setReviews(result)
    }
    setLoading(false)
  }
  
  // console.log("All Course ", courses)
  
  console.log(showType, 'showType')
  return (
    <>
      <Table className="rounded-xl border border-richblack-800 ">
        <Thead>
          <Tr className="flex gap-x-10 rounded-t-md border-b border-b-richblack-800 px-6 py-2">
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Course Name
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Email
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Rating
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Review
            </Th>
            <Th className="flex-1 text-left text-sm font-medium uppercase text-richblack-300">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reviews?.length === 0 ? (
            <Tr>
              <Td className="py-10 text-center text-2xl font-medium text-richblack-300">
                No reviews found
                {/* TODO: Need to change this state */}
              </Td>
            </Tr>
          ) : (
            (reviews.filter((revie)=> (['', 'all'].includes(showType) ? true : revie.active== (showType=='active'))))?.map((review) =>  (
              <Tr
                key={review._id}
                className="flex gap-x-10 border-b border-richblack-800 px-6 py-5"
              >
                <Td className="flex-1 text-sm font-medium text-richblack-300">
                  {review.course?.courseName}
                </Td>
                  <Td className="flex-1 text-sm font-medium text-richblack-300">
                  <div className="flex flex-col justify-between">
                    <p className="text-lg font-semibold text-richblack-300">
                      {review.user?.firstName + ' ' + review.user?.lastName || 'dummy name'}
                    </p>
                    <p className="text-[12px] text-richblack-200">
                      Email: {review.user?.email  || "no email"}
                    </p>
                  </div>
                </Td >
                <Td className="flex-1 text-sm font-medium text-richblack-300">
                 
                  <div className="flex flex-col justify-between">
                    <p className="text-lg font-semibold text-richblack-300">
                      {review.rating}
                    </p>
                    <p className="text-[12px] text-richblack-200">
                      Date: {formatDate(review.createdAt)}
                    </p>
                  </div>
                </Td>
                <Td className="flex-1 text-sm font-medium text-richblack-300">
                  {review.review}
                </Td>
                <Td className="flex-1 text-sm font-medium text-richblack-300 ">

                  <button
                    disabled={loading}
                    onClick={() => {
                      handleToggleReview(review._id, !review.active)
                    }}
                    title="Toggle"
                    className="px-2 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
                  >
                    {review.active ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
                  </button>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </>
  )
}
