import React from "react";
import { Link } from "react-router-dom";

const Button = ({ children, active, linkto }) => {
  return (
    <Link to={linkto}>
      <div
        className={` text-center text-[13px] sm:text-[18px] px-6 py-3 flex items-center rounded-3xl text-[#212529] ${
          active ? "bg-[#dbb4ac]  " : "bg-white "
        } hover:shadow-none hover:scale-95 transition-all duration-200 `}
      >
        {children}
      </div>
    </Link>
  );
};

export default Button;
