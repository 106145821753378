import React from "react"

import Footer from "../components/Common/Footer"

function Privacy() {
  return (
    <div>
      <div className="flex flex-1 items-center justify-center text-3xl text-[#000] tos">
        <div className="container privacy-container">
        <h1>Privacy Policy</h1>
        <div class="content">
            <p>
            </p>
            <h2>Services</h2>
            <p></p>
            <p>This Privacy Policy applies to your use of our Services.</p>
            <p>This Privacy Policy applies to Thapar Dog School sites, apps, communications and services (“Services”).</p>
            <p>
            </p>
            <h2>Data Controllers and Contracting Parties</h2>
            <p></p>
            <p>As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject
                to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.</p>
            <p>
            </p>
            <h2>Change</h2>
            <p></p>
            <p>Changes to the Privacy Policy apply to your use of our Services after the “effective
                date.”</p>
            <p>Thapar Dog School (“we” or “us”) can modify this Privacy Policy, and if we make
                material changes to it, we will provide notice through our Services, or by other means, to provide you
                the opportunity to review the changes before they become effective. If you object to any changes, you
                may close your account.</p>
            <p>You acknowledge that your continued use of our Services after we publish or send a notice about our
                changes to this Privacy Policy means that the collection, use and sharing of your personal data is
                subject to the updated Privacy Policy, as of its effective date.</p>
            <p>
            </p>
            <h2>1. Data We Collect</h2>
            <p></p>
            <p>
            </p>
            <h2>1.1 Data You Provide To Us</h2>
            <p></p>
            <p>You provide data to create an account with us.</p>
            <p>
            </p>
            <h2>Registration</h2>
            <p></p>
            <p>To create an account you need to provide data including your name, email address and/or mobile number,
                and a password. If you register for a premium Service, you will need to provide payment (e.g., credit
                card) and billing information.</p>
            <p>
            </p>
            <h2>Profile</h2>
            <p></p>
            <p>You have choices about the information on your profile. It’s your choice whether to include
                sensitive information on your profile and to make that sensitive information public. Please do not post
                or add personal data to your profile that you would not want to be publicly available.</p>
            <p>
            </p>
            <h2>Posting and Uploading</h2>
            <p></p>
            <p>We collect personal data from you when you provide, post or upload it to our Services, such as when you
                fill out a form, (e.g., with demographic data or salary) or respond to a survey on our
                Services.&nbsp;</p>
            <p>
            </p>
            <h2>1.2 Data From Others</h2>
            <p></p>
            <p>
            </p>
            <h2>Content and News</h2>
            <p></p>
            <p>You and others may post content that includes information about you (as part of articles, posts,
                comments, videos) on our Services. We also may collect public information about you, such as
                professional-related news and accomplishments, and make it available as part of our Services, including,
                as permitted by your settings, in notifications to others of mentions in the news.</p>
            <p>
            </p>
            <h2>1.3 Service Use</h2>
            <p></p>
            <p>We log your visits and use of our Services, including mobile apps.</p>
            <p>We log usage data when you visit or otherwise use our Services, including our sites, app and platform
                technology, such as when you view or click on content (e.g., learning video) or ads (on or off our sites
                and apps), perform a search, install or update one of our mobile apps, share articles or apply for jobs.
                We use log-ins, cookies, device information and internet protocol (“IP”) addresses to
                identify you and log your use.</p>
            <p>
            </p>
            <h2>1.4 Cookies and Similar Technologies</h2>
            <p></p>
            <p>We collect data through cookies and similar technologies.</p>
            <p>We use cookies and similar technologies (e.g., pixels and ad tags) to collect data (e.g., device IDs) to
                recognize you and your device(s) on, off and across different services and devices where you have
                engaged with our Services. We also allow some others to use cookies as described in our Cookie
                Policy.</p>
            <p>We use cookies to help keep track of items you put into your shopping cart including when you have
                abandoned your cart and this information is used to determine when to send cart reminder messages via
                SMS.</p>
            <p>
            </p>
            <h2>1.5 Your Device and Location</h2>
            <p></p>
            <p>We receive data through cookies and similar technologies</p>
            <p>When you visit or leave our Services (including some plugins and our cookies or similar technology on the
                sites of others), we may receive the URL of both the site you came from and the one you go to and the
                time of your visit. We also get information about your network and device (e.g., IP address, proxy
                server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or
                ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us
                data about your location based on your phone settings. We will ask you to opt-in before we use GPS or
                other tools to identify your precise location.</p>
            <p>
            </p>
            <h2>1.6 Messages</h2>
            <p></p>
            <p>If you communicate through our Services, we learn about that.</p>
            <p>We collect information about you when you send, receive, or engage with messages in connection with our
                Services.&nbsp;</p>
            <p>
            </p>
            <h2>1.7 Sites and Services of Others</h2>
            <p></p>
            <p>We get data when you visit sites that include our ads, cookies or some of our plugins or when you log-in
                to others’ services with your Thapar Dog School account.</p>
            <p>We receive information about your visits and interaction with services provided by others when you log-in
                with Thapar Dog School or visit others’ services that include some of our plugins or our ads, cookies or
                similar technologies.</p>
            <p>
            </p>
            <h2>1.8 Other</h2>
            <p></p>
            <p>We are improving our Services, which means we get new data and create new ways to use data.</p>
            <p>Our Services are dynamic, and we often introduce new features, which may require the collection of new
                information. If we collect materially different personal data or materially change how we collect, use
                or share your data, we will notify you and may also modify this Privacy Policy.</p>
            <p>
            </p>
            <h2>1.9 Deletion of your information</h2>
            <p></p>
            <p>You have the right to ask us to delete personal information about you if it no longer is required for the
                purpose it was collected, you have withdrawn your consent, you have a valid objection to us using your
                personal information, or our use of your personal information is contrary to law or our other legal
                obligations. Please contact us at <a href="mailto:support@thapardogschool.in">support@thapardogschool.in </a>
                to request that your personal information be deleted.</p>
            <p>
            </p>
            <h2>Key Terms&nbsp;</h2>
            <p></p>
            <p>
            </p>
            <h2>2. How We Use Your Data</h2>
            <p></p>
            <p>We use your data to provide, support, personalize and develop our Services.</p>
            <p>How we use your personal data will depend on which Services you use, how you use those Services and the
                choices you make in your settings. We use the data that we have about you to provide and personalize our
                Services, including with the help of automated systems and inferences we make, so that our Services
                (including ads) can be more relevant and useful to you and others.</p>
            <p>
            </p>
            <h2>2.1 Services</h2>
            <p></p>
            <p>Our Services help you connect with others, find and be found for work and business opportunities, stay
                informed, get training and be more productive.</p>
            <p>We use your data to authorize access to our Services and honor your settings.</p>
            <p>
            </p>
            <h2>2.2 Communications</h2>
            <p></p>
            <p>We contact you and may enable communications between Members. We offer settings to control what messages
                you receive and how often you receive some types of messages.</p>
            <p>We will contact you through email, mobile phone, notices posted on our websites or apps, and other ways
                through our Services, including text messages and push notifications. We will send you messages about
                the availability of our Services, security, or other service-related issues. We also send messages about
                how to use our Services, network updates, reminders, job suggestions and promotional messages from us
                and our partners. You may change your communication preferences at any time.</p>
            <p>
            </p>
            <h2>2.3 Advertising</h2>
            <p></p>
            <p>We may serve you tailored ads both on and off our Services. We offer you choices regarding personalized
                ads, but you cannot opt-out of seeing other ads.</p>
            <p>We may target (and measure the performance of) ads to Members, Visitors and others both on and off our
                Services directly or through a variety of partners, using the following data, whether separately or
                combined:</p>
            <p>Data from advertising technologies on and off our Services, pixels, ad tags, cookies, and device
                identifiers;</p>
            <p>Member-provided information (e.g., profile, contact information, title and industry);</p>
            <p>Data from your use of our, including as described in Section 1.3;</p>
            <p>Information from advertising partners, vendors and publishers; and</p>
            <p>Information inferred from data described above.</p>
            <p>We will show you ads called sponsored content which look similar to non-sponsored content, except that
                they are labeled as advertising (e.g., as “ad” or “sponsored”). If you take a
                social action (such as like, comment or share) on these ads, your action is associated with your name
                and viewable by others, including the advertiser.&nbsp;</p>
            <p>
            </p>
            <h2>Ad Choices</h2>
            <p></p>
            <p>We adhere to self-regulatory principles for interest-based advertising and participate in industry
                opt-outs from such ads. This does not opt you out of receiving advertising; you will continue to get
                other ads by advertisers not listed with these self regulatory tools.</p>
            <p>
            </p>
            <h2>Info to Ad Providers</h2>
            <p></p>
            <p>We do not share your personal data with any third-party advertisers or ad networks except for: (i) hashed
                IDs or device identifiers (to the extent they are personal data in some countries); (ii) with your
                separate permission (e.g., in a lead generation form) or (iii) data already visible to any users of the
                Services (e.g., profile). However, if you view or click on an ad on or off our Services, the ad provider
                will get a signal that someone visited the page that displayed the ad, and they may, through the use of
                mechanisms such as cookies, determine it is you. Advertising partners can associate personal data
                collected by the advertiser directly from you with hashed IDs or device identifiers received from us. In
                such instances, we seek to contractually require such advertising partners to obtain your explicit,
                opt-in consent before doing so.</p>
            <p>
            </p>
            <h2>2.4 Marketing</h2>
            <p></p>
            <p>We promote our Services to you and others.</p>
            <p>In addition to advertising our Services, we use Members’ data and content for invitations and
                communications promoting membership and network growth, engagement and our Services, such as by showing
                your connections that you have used a feature on our Services.</p>
            <p>
            </p>
            <h2>2.5 Developing Services and Research</h2>
            <p></p>
            <p>We develop our Services and conduct research</p>
            <p>
            </p>
            <h2>Service Development</h2>
            <p></p>
            <p>We use data, including public feedback, to conduct research and development for our Services in order to
                provide you and others with a better, more intuitive and personalized experience, and drive membership
                growth and engagement on our Services.</p>
            <p>
            </p>
            <h2>Surveys</h2>
            <p></p>
            <p>Polls and surveys are conducted by us and others through our Services. You are not obligated to respond
                to polls or surveys, and you have choices about the information you provide. You may opt-out of survey
                invitations.</p>
            <p>
            </p>
            <h2>2.6 Customer Support</h2>
            <p></p>
            <p>We use data to help you and fix problems.</p>
            <p>We use data (which can include your communications) to investigate, respond to and resolve complaints and
                for Service issues (e.g., bugs).</p>
            <p>
            </p>
            <h2>2.8 Insights That Do Not Identify You</h2>
            <p></p>
            <p>We use data to generate insights that do not identify you.</p>
            <p>We use your data to produce and share insights that do not identify you. For example, we may use your
                data to generate statistics about our members, their profession or industry, to calculate ad impressions
                served or clicked on, or to publish visitor demographics for a Service or create demographic workforce
                insights.</p>
            <p>
            </p>
            <h2>2.9 Security and Investigations</h2>
            <p></p>
            <p>We use data for security, fraud prevention and investigations.</p>
            <p>We use your data (including your communications) for security purposes or to prevent or investigate
                possible fraud or other violations of our User Agreement and/or attempts to harm our Members, Visitors
                or others.</p>
            <p>
            </p>
            <h2>Key Terms&nbsp;</h2>
            <p></p>
            <p>
            </p>
            <h2>3. How We Share Information</h2>
            <p></p>
            <p>
            </p>
            <h2>3.1 Related Services</h2>
            <p></p>
            <p>We share your data across our different Services and Thapar Dog School affiliated entities.</p>
            <p>We will share your personal data with our affiliates to provide and develop our Services. We may combine
                information internally across the different Services covered by this Privacy Policy to help our Services
                be more relevant and useful to you and others.&nbsp;</p>
            <p>
            </p>
            <p>The above excludes text messaging originator opt-in data and consent; this information will not be shared
                with any third parties.</p>
            <h2>3.2 Service Providers</h2>
            <p></p>
            <p>We may use others to help us with our Services.</p>
            <p>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud
                detection, marketing and development). They will have access to your information as reasonably necessary
                to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</p>
            <p>
            </p>
            <h2>3.3 Legal Disclosures</h2>
            <p></p>
            <p>We may need to share your data when we believe it’s required by law or to help protect the rights
                and safety of you, us or others.</p>
            <p>It is possible that we will need to disclose information about you when required by law, subpoena, or
                other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1)
                investigate, prevent or take action regarding suspected or actual illegal activities or to assist
                government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend
                ourselves against any third-party claims or allegations; (4) protect the security or integrity of our
                Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the
                rights and safety of Thapar Dog School, our Members, personnel or others. We attempt to notify Members about
                legal demands for their personal data when appropriate in our judgment, unless prohibited by law or
                court order or when the request is an emergency. We may dispute such demands when we believe, in our
                discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to
                challenge every demand.&nbsp;</p>
            <p>
            </p>
            <h2>3.4 Change in Control or Sale</h2>
            <p></p>
            <p>We may share your data when our business is sold to others, but it must continue to be used in accordance
                with this Privacy Policy.</p>
            <p>We can also share your personal data as part of a sale, merger or change in control, or in preparation
                for any of these events. Any other entity which buys us or part of our business will have the right to
                continue to use your data, but only in the manner set out in this Privacy Policy unless you agree
                otherwise.</p>
            <p>
            </p>
            <h2>4. Your Choices &amp; Obligations</h2>
            <p></p>
            <p>
            </p>
            <h2>4.1 Data Retention</h2>
            <p></p>
            <p>We keep most of your personal data for as long as your account is open.</p>
            <p>We generally retain your personal data as long as you keep your account open or as needed to provide you
                Services. This includes data you or others provided to us and data generated or inferred from your use
                of our Services. Even if you only use our Services every few years, we will retain your information and
                keep your profile open, unless you close your account. In some cases we choose to retain certain
                information (e.g., insights about Services use) in a depersonalized or aggregated form.</p>
            <p>
            </p>
            <h2>4.2 Rights to Access and Control Your Personal Data</h2>
            <p></p>
            <p>You can access or delete your personal data. You have many choices about how your data is collected, used
                and shared.</p>
            <p>We provide many choices about the collection, use and sharing of your data, from deleting or correcting
                data you include in your profile and controlling the visibility of your posts to advertising opt-outs
                and communication controls. We offer you settings to control and manage the personal data we have about
                you.</p>
            <p>For personal data that we have about you, you can:</p>
            <p>Delete Data: You can ask us to erase or delete all or some of your personal data (e.g., if it is no
                longer necessary to provide Services to you).</p>
            <p>Change or Correct Data: You can edit some of your personal data through your account. You can also ask us
                to change, update or fix your data in certain cases, particularly if it’s inaccurate.</p>
            <p>Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some of your personal
                data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your
                personal data is inaccurate or unlawfully held).</p>
            <p>Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a
                copy of personal data you provided in machine readable form.</p>
            <p>You may contact us using the contact information below, and we will consider your request in accordance
                with applicable laws.</p>
            <p>
            </p>
            <h2>4.3 Account Closure</h2>
            <p></p>
            <p>We keep some of your data even after you close your account.</p>
            <p>If you choose to close your Thapar Dog School account, your personal data will generally stop being visible to
                others on our Services within 24 hours. We generally delete closed account information within 30 days of
                account closure, except as noted below.</p>
            <p>We retain your personal data even after you have closed your account if reasonably necessary to comply
                with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve
                disputes, maintain security, prevent fraud and abuse (e.g., if we have restricted your account for
                breach of our Community Policies), enforce our User Agreement, or fulfill your request to "unsubscribe"
                from further messages from us. We will retain de-personalized information after your account has been
                closed.</p>
            <p>Information you have shared with others will remain visible after you close your account or delete the
                information from your own profile or mailbox, and we do not control data that other Members have copied
                out of our Services. Groups content and ratings or review content associated with closed accounts will
                show an unknown user as the source. Your profile may continue to be displayed in the services of others
                (e.g., search engine results) until they refresh their cache.</p>
            <p>
            </p>
            <h2>5. Other Important Information</h2>
            <p></p>
            <p>
            </p>
            <h2>5.1. Security</h2>
            <p></p>
            <p>We monitor for and try to prevent security breaches. Please use the security features available through
                our Services.</p>
            <p>We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our
                systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any
                information that you send us. There is no guarantee that data may not be accessed, disclosed, altered,
                or destroyed by breach of any of our physical, technical, or managerial safeguards. Please visit our
                Safety Center for additional information about safely using our Services, including two-factor
                authentication.</p>
            <p>
            </p>
            <h2>5.2 Lawful Bases for Processing</h2>
            <p></p>
            <p>We have lawful bases to collect, use and share data about you. You have choices about our use of your
                data. At any time, you can withdraw consent you have provided by going to settings.</p>
            <p>We will only collect and process personal data about you where we have lawful bases. Lawful bases include
                consent (where you have given consent), contract (where processing is necessary for the performance of a
                contract with you and “legitimate interests.”</p>
            <p>Where we rely on your consent to process personal data, you have the right to withdraw or decline your
                consent at any time and where we rely on legitimate interests, you have the right to object. If you have
                any questions about the lawful bases upon which we collect and use your personal data, please contact
                our Data Protection Officer here.</p>
            <p>
            </p>
            <h2>5.4. Direct Marketing and Do Not Track Signals</h2>
            <p></p>
            <p>Our statements regarding direct marketing and “do not track” signals.</p>
            <p>We currently do not share personal data with third parties for their direct marketing purposes without
                your permission. Learn more about this and about our response to “do not track” signals.</p>
            <p>
            </p>
            <h2>5.5. Contact Information</h2>
            <p></p>
            <p>You can contact us or use other options to resolve any complaints.</p>
            <p>If you have questions or complaints regarding this Policy, please first contact Thapar Dog School online. You
                can also reach us by physical mail. If contacting us does not resolve your complaint, you have more
                options.</p>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Privacy
