import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-hot-toast"

import Vimeo from '@vimeo/player'
import "video-react/dist/video-react.css"

const VimeoPlayer = ({src, onEnded, startFromStart, setStartFromStart}) => {
    const [vimeoPlayer, setVimeoPlayer] = useState(null);
    useEffect(()=>{
      try{
        if(vimeoPlayer){
          (async()=>{
            await vimeoPlayer.destroy();
          })();
        }
        let windowH = window.innerHeight;
        let windowW = document.getElementById('vimeo-player').parentElement.clientWidth;
        
        console.log(windowH, windowW, 'px')
        if(!src)return;
        var options01 = {
          id: src,
          width:windowW,
        };
        console.log('optons', options01)
        let vimorPlayer =new Vimeo('player', options01);
        vimorPlayer.setVolume(1).catch(err=>{
          toast.error('Video not found.')

        });
        // vimorPlayer.play().then()
        vimorPlayer.on('play', ()=>{
          console.log('playing')
        })
        vimorPlayer.on('ended', ()=>{
          onEnded()
          console.log('ending')
        })
        setVimeoPlayer(vimorPlayer);
      }catch(err){
        // toast.error('Video not found')
      }
    }, [src])

    useEffect(()=>{
      if(startFromStart){
        setStartFromStart(false);
        console.log(vimeoPlayer)
        if(vimeoPlayer){
          vimeoPlayer.setCurrentTime(0).then(()=>{
            vimeoPlayer.play().then();
          })
          ;
        }

      }
    }, [startFromStart])
  return (
    <div id="vimeo-player" style={{ display:'flex', justifyContent:'center'}}>


    <div id="player" >

    </div>
    </div>

  )
}

export default VimeoPlayer
// video
