import axios from "axios";
import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai";
// Icons
// import { FaFacebook, FaGoogle, FaTwitter, FaYoutube } from "react-icons/fa"
import { Link } from "react-router-dom"
const apiKey = 'TkUydTFPTHBVZEtoMVlFOFVTWE1HS2RqclRkbmxjZUFvWlMxempEUQ==';


const UserBillingDetails = ({user, onSubmit,setBillingDetailsModal}) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    stateCode: "",
    street: ''
  });
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { first_name, last_name, email, city, state,mobile, stateCode,street } = formData

  const getStates = async ()=>{
    let config = {
      method: 'get',
      url: 'https://api.countrystatecity.in/v1/countries/IN/states',
      headers: {
        'X-CSCAPI-KEY': apiKey
      }
    };
    
    axios(config)
    .then(function (response) {
      setStatesList(response.data)
      // console.log((response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const getCities = async (stateCode)=>{
    let config = {
      method: 'get',
      url: `https://api.countrystatecity.in/v1/countries/IN/states/${stateCode}/cities`,
      headers: {
        'X-CSCAPI-KEY': apiKey
      }
    };
    
    axios(config)
    .then(function (response) {
      setCityList(response.data)
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  useEffect(()=>{
    getStates();
    
  }, [])

  useEffect(()=>{
    console.log(user)
    let address = user?.additionalDetails?.metaLocation?.address || {};
    let data = {
      first_name:user?.firstName || '',
      last_name:user?.lastName || '',
      email:user?.email || '',
      mobile:user?.mobile || '',
      city: address?.city || '',
      state:address?.state || '',
      stateCode:address.stateCode || '',
      street: address.street || ''
    }
    if(data.stateCode) getCities(data.stateCode);
    setFormData(data)
  }, [user])
  // Handle input fields, when some value changes
  const handleOnChange = (e) => {
    let stateCode = ''
    let tempData = {[e.target.name]: e.target.value}
    if(e.target.name=='state'){
      statesList.forEach((state)=>{
        if(state.name==e.target.value){
          stateCode = state.iso2;
          switch(state){
            case 'Odisha': 
              tempData['stateCode'] = 'OD';
              break;
            case 'Andhra Pradesh':
              tempData['stateCode'] = 'AD';
              break;
            case 'Uttarakhand':
              tempData['stateCode'] = 'UK';
              break;
            case 'Telangana':
              tempData['stateCode'] = 'TS';
              break;
            case 'Chhattisgarh':
              tempData['stateCode'] = 'CG';
              break;
            default:
              tempData['stateCode'] = stateCode;
          }
        }
      })
      getCities(stateCode);
    }
    setFormData((prevData) => ({
      ...prevData,
      ...tempData
    }))
  }
  const handleOnSubmit = (e)=>{
    e.preventDefault()

    if (!city) {
      toast.error("Invalid City.")
      return;
    }
    if (!state) {
      toast.error("Invalid State.")
      return;
    }

    if(!mobile || mobile.length!=10){
      toast.error("Invalid mobile number.")
      return;
    }
    if(!first_name){
      toast.error("First name is required.")
      return;
    }
    if(!email){
      toast.error("Email is required.")
      return;
    }
    onSubmit(formData)
  }
  return (
    <div className="user-billing" style={{width: '100vw', display:'flex',position:'fixed',top:'120px', justifyContent:'center', padding:'10px', zIndex:'11'}}>
      <div style={{width: '100vw', minHeight:'100vh',background:'black', opacity: '0.3', position:'fixed',top:'80px'}} onClick={()=>setBillingDetailsModal(false)}></div>
      <div className="lg:block hidden block w-6/12 bg-[#ffffff] py-4 px-5" style={{zIndex:13}}>

        <div className="flex justify-between">
          <p></p>
        <h1 className="text-center text-3xl font-semibold">
            Billing Details
        </h1>
          <p onClick={()=>setBillingDetailsModal(false)} className="cursor-pointer"><AiOutlineClose/></p>
        </div>
        <form onSubmit={handleOnSubmit} className="flex w-full flex-col items-center gap-y-4 p-1 my-6 lg-page ">
        <div className="w-full flex gap-x-4">
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              First Name <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="first_name"
              value={first_name}
              onChange={handleOnChange}
              placeholder="Enter first name"
              className="form-style w-full"
            />
          </label>
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Last Name <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="last_name"
              value={last_name}
              onChange={handleOnChange}
              placeholder="Enter last name"
              className="form-style w-full"
            />
          </label>
        </div>
        <div className="w-full flex gap-x-4">
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Email Address <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="email"
              value={email}
              onChange={handleOnChange}
              placeholder="Enter email address"
              className="form-style w-full"
              disabled
            />
          </label>
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
            Mobile Number  <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="number"
              name="mobile"
              value={mobile}
              onChange={handleOnChange}
              placeholder="Enter mobile address"
              maxLength={10}
              minLength={10}
              className="form-style w-full"
            />
          </label>
        </div>
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
            Street Address 
          </p>
          <input
            required
            type="text"
            name="street"
            value={street}
            onChange={handleOnChange}
            placeholder="Enter street address"
            className="form-style w-full"
          />
        </label>
        <div className="w-full flex gap-x-4">
          <label className=" flex-1 relative">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Select State <sup className="text-pink-200">*</sup>
            </p>
            <select
              required
              type="text"
              name="state"
              value={state}
              onChange={handleOnChange}
              placeholder="Select State"
              className="form-style w-full !pr-10"
            >
              <option value="" disabled>Select State</option>
              {statesList.map((state)=><option value={state.name}>{state.name}</option>)}
            </select>
          </label>
          <label className="flex-1 relative">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              select City<sup className="text-pink-200">*</sup>
            </p>
            <select
              required
              type='text'
              name="city"
              value={city}
              onChange={handleOnChange}
              placeholder="City"
              className="form-style w-full !pr-10"
            >

              <option value="" disabled>Select City</option>
              {cityList.map((city)=>(<option value={city.name}>{city.name}</option>))}
            </select>
          </label>
        </div>
        <button
          type="submit"
          className="mt-3 rounded-[8px] bg-[#dbb4ac] py-[8px] px-[60px] font-medium text-richblack-900"
        >
          Proceed to Pay
        </button>
      </form>
      </div>
      <div className="lg:hidden block w-11/12 bg-[#ffffff] py-4 px-5 h-[100vh]" style={{zIndex:13}}>

        <div className="flex justify-between">
          <p></p>
        <h1 className="text-center text-3xl font-semibold">
            Billing Details
        </h1>
          <p onClick={()=>setBillingDetailsModal(false)} className="cursor-pointer"><AiOutlineClose/></p>
        </div>
        <form onSubmit={handleOnSubmit} className="flex w-full flex-col items-center gap-y-4 p-1 my-6 lg-page ">
        <div className="w-full flex gap-x-4">
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              First Name <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="first_name"
              value={first_name}
              onChange={handleOnChange}
              placeholder="Enter first name"
              className="form-style w-full"
            />
          </label>
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Last Name <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="last_name"
              value={last_name}
              onChange={handleOnChange}
              placeholder="Enter last name"
              className="form-style w-full"
            />
          </label>
        </div>
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
            Email Address <sup className="text-pink-200">*</sup>
          </p>
          <input
            required
            type="text"
            name="email"
            value={email}
            onChange={handleOnChange}
            placeholder="Enter email address"
            className="form-style w-full"
            disabled
          />
        </label>
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
            Mobile Number <sup className="text-pink-200">*</sup>
          </p>
          <input
            required
            type="number"
            name="mobile"
            value={mobile}
            onChange={handleOnChange}
            placeholder="Enter mobile number"
            className="form-style w-full"
            maxLength={10}
            minLength={10}
          />
        </label>
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
            Street Address <sup className="text-pink-200">*</sup>
          </p>
          <input
            required
            type="text"
            name="street"
            value={street}
            onChange={handleOnChange}
            placeholder="Enter street address"
            className="form-style w-full"
          />
        </label>
        <div className="w-full flex gap-x-4">
          <label className=" flex-1 relative">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Select State <sup className="text-pink-200">*</sup>
            </p>
            <select
              required
              type="text"
              name="state"
              value={state}
              onChange={handleOnChange}
              placeholder="Select State"
              className="form-style w-full !pr-10"
            >
              <option value="" disabled>Select State</option>
              {statesList.map((state)=><option value={state.name}>{state.name}</option>)}
            </select>
          </label>
          <label className="flex-1 relative">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              select City<sup className="text-pink-200">*</sup>
            </p>
            <select
              required
              type='text'
              name="city"
              value={city}
              onChange={handleOnChange}
              placeholder="City"
              className="form-style w-full !pr-10"
            >

              <option value="" disabled>Select City</option>
              {cityList.map((city)=>(<option value={city.name}>{city.name}</option>))}
            </select>
          </label>
        </div>
        <button
          type="submit"
          className="mt-3 rounded-[8px] bg-[#dbb4ac] py-[8px] px-[60px] font-medium text-richblack-900"
        >
          Proceed to Pay
        </button>
      </form>
      </div>
    </div>
  )
}

export default UserBillingDetails
