import { toast } from "react-hot-toast"

import { updateCompletedLectures } from "../../slices/viewCourseSlice"
// import { setLoading } from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector"
import { customerEndpoints, ratingsEndpoints } from "../apis"

const {
GET_ALL_CUSTOMERS
} = customerEndpoints

export const getAllCustomers = async (token, params) => {
  const toastId = toast.loading("Loading...")
  let result = [];
  let totalCustomers = 0;
  let clients = 0;
  try {
    const response = await apiConnector("GET", GET_ALL_CUSTOMERS, null,{
        Authorization: `Bearer ${token}`,
    }, params)
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch Customers Data")
    }
    result = response?.data?.data
    totalCustomers = response.data.total;
    clients = response.data.clients;
  } catch (error) {
    console.log("GET_ALL_CUSTOMERS API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return {result, totalCustomers, clients}
}

export const getAllReviews = async (token) => {
  const toastId = toast.loading("Loading...")
  let result = []
  try {
    const response = await apiConnector("POST", ratingsEndpoints.REVIEWS_DETAILS_API +'/admin', null,{
        Authorization: `Bearer ${token}`,
    })
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch Reviews Data")
    }
    result = response?.data?.data
  } catch (error) {
    console.log("REVIEWS_DETAILS_API API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return result
}

export const toggleReviewVisibility = async (reviewId, body, token) => {
  const toastId = toast.loading("Loading...")
  let result = []
  try {
    const response = await apiConnector("POST", ratingsEndpoints.TOGGLE_REVIEW_VISIBILITY +`/${reviewId}`, body,{
        Authorization: `Bearer ${token}`,
    })
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch Reviews Data")
    }
    result = response?.data?.data
  } catch (error) {
    console.log("REVIEWS_DETAILS_API API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return result
}