import { useEffect, useState } from "react"
import { VscAdd } from "react-icons/vsc"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { fetchInstructorCourses, fetchAdminCourses } from "../../../services/operations/courseDetailsAPI"
import IconBtn from "../../Common/IconBtn"
import CoursesTable from "./InstructorCourses/CoursesTable"
import { ACCOUNT_TYPE } from "../../../utils/constants"
import { generateInvoiceNumber } from "../../../services/operations/studentFeaturesAPI"

export default function MyCourses() {
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const [courses, setCourses] = useState([])
  const [latestInvoceNumber, setLatestInvoceNumber] = useState('null')

  useEffect(() => {
    const fetchCourses = async () => {
      const result = await fetchAdminCourses(token)
      if (result) {
        setCourses(result)
      }
    }
    fetchCourses()
     
  }, [])

  const readCookie = (cookiename)=> {
    let nameEQ = cookiename + "=";
    let ca = document.cookie.split(';');
    // console.info('ca', ca);
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const generateNewInvoiceNumber = async ()=>{
    let invoiceNumber = readCookie('tds_invoice_number');
    if(invoiceNumber){
      setLatestInvoceNumber(invoiceNumber)
    }else{
      invoiceNumber = await generateInvoiceNumber(token);
      setLatestInvoceNumber(invoiceNumber);
    }

  }

  return (
    <div>
      <div className="mb-14 flex items-center justify-between">
        <h1 className="text-3xl font-medium text-[#313131]">My Courses</h1>
        <div className="space-y-2">
          <h1 className="text-2xl font-bold text-[#313131] text-center rounded-md py-2 px-5" style={{background:'#e3e3e3'}}>
            {latestInvoceNumber}👋
          </h1>
          <button className="cursor-pointer gap-x-2 rounded-md py-2 px-5 font-semibold text-richblack-900" onClick={()=>generateNewInvoiceNumber()} style={{background: 'black', color:'white'}}>
            Generate Invoice Number
          </button>
        </div>
        <IconBtn
          text="Add Course"
          onclick={() => navigate("/dashboard/add-course")}
        >
          <VscAdd />
        </IconBtn>
      </div>
      {courses && <CoursesTable courses={courses} setCourses={setCourses} />}
    </div>
  )
}
