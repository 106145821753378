import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { BuyCourse, verifyCoupon } from "../../../../services/operations/studentFeaturesAPI"
import IconBtn from "../../../Common/IconBtn"
import { useState } from "react"
import UserBillingDetails from "../../../Common/userBillingDetails"

export default function RenderTotalAmount() {
  const { total, cart } = useSelector((state) => state.cart)
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const [promo, setPromo] = useState('');
  const [promoApplied, setPromoApplied] = useState(false);
  const [discountedPrice, setDiscoutedPrice] = useState(0);
  const [promoLoading, setPromoLoading] = useState(false);
  const [billingDetailsModal, setBillingDetailsModal] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleBuyCourse = (billingDetails) => {
    const courses = cart.map((course) => course._id)
    BuyCourse(token, courses, user,promoApplied? promo: '',billingDetails, navigate, dispatch)
  }

  const handleApplyPromo = async ()=>{
    setPromoLoading(true);
    let discount = await verifyCoupon(promo, cart[0]?._id);
    if(discount){
      setPromoApplied(true);
      let newPrice = +total - (+total * (+discount/100));

      setDiscoutedPrice(newPrice.toFixed(2));
    }
    setPromoLoading(false);

 }
 const billingDetailsSubmitted = (billing_details)=>{
  let billingDetails = ({...billing_details, address: {city: billing_details.city, state: billing_details.state, stateCode: billing_details.stateCode,street:billing_details.street}});
  setBillingDetailsModal(false);
  handleBuyCourse(billingDetails);
 }  

  return (
    <>
    {billingDetailsModal && <UserBillingDetails user={user} onSubmit={billingDetailsSubmitted} setBillingDetailsModal={setBillingDetailsModal}/>}

    <div className="min-w-[280px] rounded-md border-[1px] border-richblack-700 bg-richblack-800 p-6">
      <p className="mb-1 text-sm font-medium text-richblack-300">Total:</p>
      <p className="mb-6 text-3xl font-medium text-yellow-100">
        {promoApplied ?(<> <del className="font-light">₹  {total}</del>  ₹ {discountedPrice} </>  ): 'Rs. ' + total}
      </p>
      <div className="flex gap-2 py-3">
            <input type="text" value={promo} className="rounded-md text-center bg-[#2c333f]" style={{color:'whitesmoke'}} onInput={(e)=>setPromo(e.target.value)} placeholder="Add Promo code" disabled={promoApplied}/>
            <button
              className="yellowButton w-full"
              onClick={
                ()=>{handleApplyPromo()}
              }
              disabled={promoApplied || promoLoading}
            >
             {promoLoading ? 'loading' : promoApplied ? 'Applied' : 'Apply'}
            </button>
      </div>
      <IconBtn
        text="Buy Now"
        onclick={handleBuyCourse}
        customClasses="w-full justify-center"
      />
    </div>
    </>
  )
}
