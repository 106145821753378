import React from "react"

import Footer from "../components/Common/Footer"
import ReviewSlider from "../components/Common/ReviewSlider"
import ContactFormSection from "../components/core/AboutPage/ContactFormSection"
import LearningGrid from "../components/core/AboutPage/LearningGrid"
// import Quote from "../components/core/AboutPage/Quote"
import StatsComponenet from "../components/core/AboutPage/Stats"
// import HighlightText from "../components/core/HomePage/HighlightText"

const About = () => {
  const quoteColor = "#313131"
  return (
    <div className="about-sec">
      <section>
        <div className="mx-auto my-5 flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-richblack-500">
          <div className="s-mt-4xl flex flex-col-reverse items-center justify-between gap-10 lg:flex-row ">
            <div className="s-m-0 my-24 flex flex-col gap-10 lg:w-[50%]">
              <h1 className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] ">
                Our Founding Story
              </h1>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                🐾 Welcome to Thapar Dog School: A Journey of Canine Connection
                Meet Rishi Thapar, the passionate soul behind Thapar Dog School,
                where the journey of dog training transcends beyond obedience
                into a realm of trust, love, and understanding. With a legacy
                spanning 19 years, Rishi has been the guiding force for
                countless furry companions and their devoted owners.
              </p>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                🏡 From Offline to Online: Pioneering the Canine Learning
                Revolution For nearly two decades, Rishi dedicated himself to
                the art of dog training, operating offline and welcoming dogs
                into his training haven for a transformative 10-day program.
                Dogs not only learned basic commands like sitting, staying, and
                walking beside their owners but also imbibed essential life
                skills such as proper eating habits and responsible outdoor
                behavior. Rishi's unique approach focused on resolving
                behavioral issues without resorting to harsh methods,
                establishing an environment of trust and mutual understanding.
              </p>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                🚀 Going Viral, Unleashing Demand: The Turning Point Recently,
                Thapar Dog School went viral on Instagram, catapulting Rishi
                into the limelight. The surge in demand, however, posed a
                delightful challenge. Rishi found himself at a crossroads, with
                an overwhelming number of pet parents seeking his expertise.
                Yet, some hesitated due to concerns—ranging from leaving their
                beloved pets for 10 days to fears of traditional training
                methods involving force.
              </p>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                🌐 Bridging Gaps: Thapar Dog School Goes Digital In response to
                this paradigm shift, Rishi envisioned a solution that would
                bring the essence of Thapar Dog School to every corner of the
                globe. Introducing the Online Dog Training Course—an innovative
                platform designed for pet parents who prefer the comfort of home
                while ensuring their dogs receive top-notch training.
              </p>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                🏠 Train Anytime, Anywhere: Your Dog's Education, Your Way
                Thapar Dog School's Online Training Course empowers dog owners
                to take charge of their pet's education. No longer constrained
                by geographical distances or concerns about separation, pet
                parents can now embark on a journey of training and bonding with
                their furry friends, all within the familiar confines of their
                homes.
              </p>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                🚫 No Beating, Only Bonding: The Thapar Way True to his ethos,
                Rishi ensures that the online training course mirrors the values
                upheld at Thapar Dog School. There's no room for harsh methods;
                instead, the course fosters a bond built on trust, positive
                reinforcement, and effective communication between pet and
                owner.
              </p>
              Join Rishi Thapar on this groundbreaking venture, where Thapar Dog
              School transitions from the tangible to the digital, making canine
              education accessible to all. Discover the joy of training your
              furry companion and witness the transformation that goes beyond
              commands—it's a journey of connection, understanding, and a
              lifetime of wagging tails. 🐶💙
            </div>

            <div className="s-m-0 my-24 flex flex-col gap-10 lg:w-[50%]">
              <img
                src="https://res.cloudinary.com/dozkyuutd/image/upload/v1710592506/testFolder2/ofu2ovewu5dlnd6ddi8x.jpg"
                alt=""
                className="shadow-[0_0_20px_0] shadow-[#FC6767]"
              />
              <img
                src="https://res.cloudinary.com/dozkyuutd/image/upload/v1710592564/testFolder2/hbb1ifbtq3jaeb0jh2ww.jpg"
                alt=""
                className="shadow-[0_0_20px_0] shadow-[#FC6767]"
              />
              {/* <img
                src={FoundingStory}
                alt=""
                className="shadow-[0_0_20px_0] shadow-[#FC6767]"
              /> */}
            </div>
          </div>
        </div>
      </section>

      <StatsComponenet />
      <section className="mx-auto mt-20 flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-white">
        {/* <LearningGrid /> */}
        <ContactFormSection />
      </section>
      <div className="relative mx-auto my-20 flex w-full lg:w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 bg-richblack-900 text-white">
        {/* Reviws from Other Learner */}
        <h1 className="mt-8 text-center text-4xl font-semibold">
          Reviews from other learners
        </h1>
        <ReviewSlider />
      </div>
      <Footer />
    </div>
  )
}

export default About
