import { useEffect, useState } from "react"
import { VscArrowDown, VscArrowUp } from "react-icons/vsc"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { apiConnector } from "../../../services/apiConnector"
import { getAllReviews } from "../../../services/operations/customerDetailsAPI"
import IconBtn from "../../Common/IconBtn"
import ReviewsTable from "./AdminReviews/ReviewsTable"

export default function Reviews() {
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const [reviews, setReviews] = useState([])
  const [dateAcen, setDateAcen] = useState(true)
  const [ratingAcen, setRatingAcen] = useState(true);
  const [showType, setShowType] = useState('')
  const fetchCourses = async () => {
    const result = await getAllReviews(token)
    if (result) {
      setReviews(result)
    }
  }

  const sortByDate = async (ascending) => {
    setDateAcen(ascending);
    setReviews(reviews.toSorted((a,b)=>{
        let aDate = new Date(a.createdAt);
        let bDate = new Date(b.createdAt);
        return ascending ? aDate - bDate : bDate - aDate
    }))
  }

  const sortByRating = async (ascending) => {
    setRatingAcen(ascending);
    setReviews(reviews.toSorted((a,b)=>{
        return ascending ? a.rating - b.rating : b.rating - a.rating
    }))
  }

  useEffect(() => {
    fetchCourses()
  }, [])

  return (
    <div>
      <div className="mb-14 flex items-center justify-between">
        <h1 className="text-3xl font-medium text-[#313131]">Reviews</h1>
          
        <select
          defaultValue=""
          id="reviewType"
          className="flex items-center bg-[#dbb4ac] cursor-pointer gap-x-2 rounded-md py-2 px-5 font-semibold text-richblack-900"
          onChange={(e)=>setShowType(e.target.value)}
        >
          <option value="" disabled>
            Review type
          </option>
          <option value="all">
            All
          </option>

          <option value="active">Active</option>
          <option value="inactive">In Active</option>
        </select>

        <IconBtn
          text="Sort By Rating"
          onclick={() => {
            sortByRating(!ratingAcen)
          }}
        >
          {ratingAcen ? <VscArrowUp /> : <VscArrowDown />}
        </IconBtn>
        <IconBtn
          text="Sort By Date"
          onclick={() => {
            sortByDate(!dateAcen)
          }}
        >
          {dateAcen ? <VscArrowUp /> : <VscArrowDown />}
        </IconBtn>
      </div>
      {reviews && <ReviewsTable showType={showType} reviews={reviews} setReviews={setReviews} />}
    </div>
  )
}
